import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import Redirect from "../../components/Redirect/Redirect";
import Button from "../../components/basics/Button/Button";
import { IAuthContextProps } from "../../context/authProvider";
import useAuth from "../../context/useAuth";
import { CONSENT_GRANT_PATH } from "../../util/endpoints";
import { postWithCredentials } from "../../util/fetch/fetchRequests";
import { IConsent } from "../Welcome";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IParam {
  state: IConsent;
}

const ApplyConsent = () => {
  const location: IParam = useLocation();
  const auth: IAuthContextProps = useAuth();
  const nav = useNavigate();
  const [banner, setBanner] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [bannerText, setBannerText] = useState("");
  const [bannerType, setBannerType] = useState<"success" | "error">("success");
  const [externalRedirectUri, setExternalRedirectUri] = useState<
    string | undefined
  >(undefined);

  function getHref(
    result: "decline" | "success" | "failure",
    authContext: IAuthContextProps
  ) {
    const state = authContext.auth?.state
      ? "&state=" + authContext.auth.state
      : "";
    const uri =
      authContext.auth?.redirectUrl +
      (authContext.auth?.redirectUrl.includes("?") ? "&" : "?");
    return uri + `result=${result}&user=${authContext.auth?.user}${state}`;
  }

  const redirectToPage = (result: "decline" | "success" | "failure") => {
    const href = getHref(result, auth);
    if (new URL(href).origin === window.location.origin) {
      window.location.assign(href);
    } else {
      setExternalRedirectUri(href);
    }
  };

  const handleDecline = async () => {
    setBanner(false);

    try {
      await postWithCredentials(
        CONSENT_GRANT_PATH +
          `?documentId=${auth.auth?.documentId}&decision=false`,
        auth.auth?.access_token
      );
      setBannerText("Successfully consent declined. You will be redirected!");
      setBannerType("success");
      setBanner(true);
      redirectToPage("decline");
    } catch (e) {
      if (e.status === 401) {
        goToErroPage();
      } else {
        setBannerText(e.info);
        setBannerType("error");
        setBanner(true);
        redirectToPage("failure");
      }
    }
  };

  const handleSave = async () => {
    setBanner(false);

    try {
      await postWithCredentials(
        CONSENT_GRANT_PATH +
          `?documentId=${auth.auth?.documentId}&decision=true`,
        auth.auth?.access_token
      );
      setBannerText("Successfully consent saved. You will be redirected!");
      setBannerType("success");
      setBanner(true);
      redirectToPage("success");
    } catch (e) {
      if (e.status === 401) {
        goToErroPage();
      } else {
        setBannerText(e.info);
        setBannerType("error");
        setBanner(true);
        redirectToPage("failure");
      }
    }
  };

  function goToErroPage() {
    nav("/error", {
      state: {
        title: "Ihre Sitzung ist abgelaufen.",
        err: "Bitte melden Sie sich erneut an.",
      },
    });
  }

  useEffect(() => {
    if (!location.state.consentRequired && location.state.consent.decision) {
      redirectToPage("success");
    }
    setShowPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location]);

  return (
    <>
      {externalRedirectUri && (
        <Redirect
          page={externalRedirectUri}
          cancel={() => setExternalRedirectUri(undefined)}
        />
      )}
      {!externalRedirectUri && showPage && (
        <>
          <Banner
            text={bannerText}
            type={bannerType}
            visible={banner}
            testId="apply-content-page-banner"
          />

          <div
            className="mb-24 mt-16 max-w-maxContent px-8 md:mx-auto "
            data-testid="apply-content-page-container"
          >
            <div
              className="h-100% text-dark lg:w-2/3"
              data-testid="apply-content-page-text-container"
            >
              <h1
                className="md:max-w-2/3 mb-5 font-akzidenz text-textMdDesk uppercase"
                data-testid="apply-content-page-text-healine"
              >
                <ReactMarkdown>
                  {location.state?.versionDetails?.title}
                </ReactMarkdown>
              </h1>

              <p
                className="mb-6 text-textMdMob "
                data-testid="apply-content-page-text-first-part"
              >
                <ReactMarkdown className="reactMarkDown">
                  {location.state?.versionDetails?.text}
                </ReactMarkdown>
              </p>
              <h2 className="text-textMdMob font-bold">Widerrufsmöglichkeit</h2>
              <p
                className={` mb-6 text-textMdMob`}
                data-testid="apply-content-page-text-second-part"
              >
                <ReactMarkdown
                  className="reactMarkDown"
                  remarkPlugins={[remarkGfm]}
                >
                  {location.state?.versionDetails?.disclaimer}
                </ReactMarkdown>
              </p>
            </div>
            <div className="mt-8 flex gap-4">
              <Button
                type="reset"
                onClick={handleDecline}
                testId="consent-ui-consent-cancel-button"
                variant="secondary"
                inverted={true}
                extraClass="flex items-center mt-5"
              >
                <p className="font-akzidenz text-dispXsBold">Ablehnen</p>
              </Button>
              <Button
                onClick={handleSave}
                type="submit"
                testId="consent-ui-consent-accept-button"
                variant="primary"
                inverted={true}
                extraClass="flex items-center mt-5"
              >
                <p className="font-akzidenz text-dispXsBold">Einwilligen</p>
              </Button>
            </div>
          </div>
        </>
      )}
      {!externalRedirectUri && <Footer />}
    </>
  );
};

export default ApplyConsent;
