import React, { PropsWithChildren, createContext, useState } from "react";

export type IAuth = {
  isLoggedIn: boolean;
  access_token: string;
  email: string;
  documentId: string;
  subscriptionId: string;
  state?: string;
  brand: string;
  language: string;
  redirectUrl: string;
  user: string;
};

export type IAuthContextProps = {
  auth?: IAuth;
  addAuth: (data: IAuth) => void;
};

export const AuthContext: React.Context<IAuthContextProps> =
  createContext<IAuthContextProps>({
    auth: {
      isLoggedIn: false,
      access_token: "",
      email: "",
      documentId: "",
      subscriptionId: "",
      brand: "",
      redirectUrl: "",
      language: "",
      user: "",
    },
    addAuth: () => {
      // This is intentional
    },
  });

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { auth, addAuth } = useAuth();
  return (
    <AuthContext.Provider value={{ auth, addAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const [auth, setAuth] = useState<IAuth>();

  const addAuth = (data: IAuth) => {
    setAuth(data);
  };

  return { auth, addAuth };
};
