
interface IButton
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  extraClass?: string;
  variant?: "primary" | "secondary";
  inverted?: boolean;
  testId: string;
  ariaControls?: string;
  ariaHasPopup?: boolean;
  children: React.ReactNode;
}

const Button = (props: IButton) => {
  const {
    children,
    onClick,
    extraClass,
    variant,
    inverted,
    type,
    testId,
    disabled,
    ariaControls,
    ariaHasPopup,
  } = props;

  const btnPrimary = `disabled:bg-grey-base disabled:text-grey-dark ${
    inverted
      ? "text-accent-base bg-primary-dark hover:text-accent-dark"
      : "bg-accent-base text-primary-dark hover:bg-accent-dark"
  }`;

  const btnSecondary = `disabled:bg-secondary-dark disabled:text-primary-dark border ${
    inverted
      ? "border-primary-dark bg-primary-white text-primary-dark hover:text-primary-white hover:bg-primary-dark hover:border-primary-white"
      : "border-primary-white text-primary-white bg-primary-dark hover:text-primary-dark hover:bg-primary-white hover:border-primary-dark"
  }`;

  const btnVariant = variant === "primary" ? btnPrimary : btnSecondary;

  return (
    <button
      className={`rounded-sm px-6 py-2.5 font-akzidenz text-dispXs ${extraClass} ${btnVariant}`}
      onClick={onClick}
      type={type}
      data-testid={testId}
      disabled={disabled}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
    >
      {children}
    </button>
  );
};

export default Button;
