import axios from "axios";

const TIMEOUT_IN_MILLISECONDS = 30000;

class APIError extends Error {
  info: string | undefined;
  status: number | undefined;
}

export function handleError(err: any) {
  const error = new APIError("An error occurred while fetching the data.");
  if (err.response.status === 401) {
    error.info = "Your session has Expired.";
  } else {
    const mess = err.response.data.error;
    error.info = mess ? mess : err.response.data.text;
  }
  error.status = err.response.status;
  throw error;
}

const postRequest = (url: string, token: string | undefined) => {
  const HEADER = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(url, null, {
      withCredentials: true,
      headers: HEADER,
      timeout: TIMEOUT_IN_MILLISECONDS,
    })
    .then((res) => res.data)
    .catch((err) => {
      handleError(err);
    });
};

const getRequest = (url: string, token: string | undefined) => {
  let HEADER;
  if (token) {
    HEADER = { Authorization: `Bearer ${token}` };
  }
  return axios
    .get(url, {
      withCredentials: true,
      timeout: TIMEOUT_IN_MILLISECONDS,
      headers: HEADER,
    })
    .then((res) => res.data)
    .catch((err) => {
      handleError(err);
    });
};

export const postWithCredentials = async (
  url: string,
  token: string | undefined
) => {
  return postRequest(url, token);
};

export const getWithCredentials = async (url: string, token?: string) => {
  return getRequest(url, token);
};
