import { useLocation } from "react-router-dom";

const Error404 = () => {
  const { state } = useLocation();

  const errorBody = () => {
    return (
      <div>
        <span className="pt-5 text-textMdMob md:text-textMdMob">
          Der Link, dem Sie gefolgt sind, ist möglicherweise fehlerhaft, oder
          die Seite wurde entfernt.
        </span>
        <br />
        <span className="pt-5 text-textMdMob md:text-textMdMob">
          Bitte versuchen Sie es erneut.
        </span>
      </div>
    );
  };

  return (
    <div className="px-4 py-16 pb-24 md:py-32">
      <div
        className="max-w-maxContent px-4 md:mx-auto"
        data-testid="error-page-container"
      >
        <div className="max-w-[800px]">
          <div
            className="font-akzidenz text-dispLgMob uppercase md:text-dispLgDesk"
            data-testid="error-page-title"
          >
            {state && state.title
              ? state.title
              : "Es tut uns Leid, es ist ein Fehler aufgetreten"}
          </div>
          <div className="pt-8" data-testid="error-page-text">
            {state && state.err ? state.err : errorBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
