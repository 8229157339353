import Button from '../basics/Button/Button'
import Spinner from './Spinner/Spinner'
import { useEffect } from 'react'

const Redirect = ({ page, cancel }: { page: string; cancel: () => void }) => {
  const TIMEOUT_IN_MILLI = 3000

  window.scrollTo(0, 0)

  useEffect(() => {
    const interval = setTimeout(() => {
      window.location.href = page
    }, TIMEOUT_IN_MILLI)
    return () => clearTimeout(interval)
  }, [page])

  return (
    <div className="flex h-screen w-full justify-center ">
      <Spinner />
      <div className="mt-52 px-6 flex flex-col items-center text-center font-akzidenz">
        <p className="text-textMdMob uppercase md:text-textMdDesk">
          Sie werden weitergeleitet auf die Domain
          <br />
          <span data-testid="apply-consent-page-url" className="break-all">
            {new URL(page).origin}
          </span>
        </p>

        <div className="max-w-[50%] font-montreal text-textSm">
          <p className="mt-12">
            Wenn Sie nicht weitergeleitet werden möchten,{' '}
            <span
              data-testid="apply-consent-page-cancel-button"
              onClick={cancel}
              className="cursor-pointer text-accent-dark underline"
            >
              kehren Sie bitte zur vorherigen Seite zurück
            </span>
          </p>
          <p className="mt-12">
            Sollten Sie nach {TIMEOUT_IN_MILLI / 1000} Sekunden noch nicht
            weitergeleitet worden sein, klicken Sie bitte auf Weiter.
          </p>
        </div>

        <Button
          extraClass="mt-8"
          testId="Redirect-Page-Weiter-Button"
          variant="secondary"
          inverted={true}
          onClick={() => (window.location.href = page)}
        >
          Weiter
        </Button>
      </div>
    </div>
  )
}

export default Redirect
