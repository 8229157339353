import jwtDecode from "jwt-decode";
import { IConsent } from ".";
import {
  IDENTITY_KIT_LOGIN_URL,
  IDENTITY_KIT_NONCE,
  URI_SEPARATION_SIGN,
} from "../../util/endpoints";
import { MAGIC_LINK_PARAM } from "../../util/types";

export function getValueUriParams(
  url: string,
  key: "access_token" | "id_token" | "state"
) {
  return url
    ?.split("&")
    ?.filter((value) => value.replace("#", "").startsWith(key))[0]
    ?.split(/=(.*)/s)[1];
}

export function getValueStateParams(params: string, key: MAGIC_LINK_PARAM) {
  return params
    ?.split(URI_SEPARATION_SIGN)
    ?.filter((value) => value.startsWith(key))[0]
    ?.substring(key.length + 1);
}

export function createAuthObj(
  token: string,
  state: string,
  consent: IConsent,
  jwt: {
    email: string;
    nonce: string;
    aud: string;
    iss: string;
    exp: number;
    sub: string;
  }
) {
  return {
    isLoggedIn: true,
    access_token: token,
    email: jwt.email,
    subscriptionId: getValueStateParams(state, MAGIC_LINK_PARAM.subscriptionId),
    documentId: consent?.documentId,
    state: getValueStateParams(state, MAGIC_LINK_PARAM.state),
    brand: getValueStateParams(state, MAGIC_LINK_PARAM.brand),
    language: getValueStateParams(state, MAGIC_LINK_PARAM.language),
    redirectUrl: getValueStateParams(state, MAGIC_LINK_PARAM.redirect),
    user: jwt.sub,
  };
}

export function getJwtDecode(jwt: string) {
  return jwtDecode<{
    email: string;
    nonce: string;
    aud: string;
    iss: string;
    sub: string;
    exp: number;
  }>(jwt);
}

export function validateToken(decode: {
  email: string;
  nonce: string;
  aud: string;
  iss: string;
  exp: number;
}) {
  return (
    decode.aud === process.env.REACT_APP_IDENTITY_KIT_CLIENT_ID &&
    decode.iss === IDENTITY_KIT_LOGIN_URL &&
    decode.nonce === IDENTITY_KIT_NONCE &&
    new Date(decode.exp * 1000) >= new Date()
  );
}
