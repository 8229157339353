import { REACT_APP_DATAHUB_PATH } from "../../util/endpoints";

const Footer = () => {
  return (
    <footer className="bg-primary-dark px-4 pb-12 pt-16 text-primary-white md:px-8">
      <div
        data-testid="footer-container"
        className="mx-auto max-w-maxContent justify-between md:flex"
      >
        <nav className="mb-8 grow">
          <h2
            className="mb-4 text-textXs text-secondary-dark"
            data-testid="footer-menu-title-legal"
          >
            Legal notice
          </h2>
          <ul className="font-akzidenz">
            <li className="mb-3">
              <a
                href={`${REACT_APP_DATAHUB_PATH}/legal-notice/privacy-notice/data-hub`}
                target="_blank"
                data-testid={"footer-menu-legal-link-privacy-policy"}
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </li>
            <li className="mb-3">
              <a
                href={`${REACT_APP_DATAHUB_PATH}/legal-notice/cookie-policy`}
                target="_blank"
                data-testid="footer-menu-legal-link-cookie-privacy"
                rel="noreferrer"
              >
                Cookie policy
              </a>
            </li>
            <li className="mb-3">
              <a
                href={`${REACT_APP_DATAHUB_PATH}/imprint`}
                target="_blank"
                data-testid="footer-menu-legal-link-imprint"
                rel="noreferrer"
              >
                Imprint
              </a>
            </li>
          </ul>
        </nav>
        <div className="grow md:order-first">
          <small
            className="text-textXs text-secondary-dark"
            data-testid={"footer-menu-copy-right"}
          >
            Copyright Volkswagen
            <br />
            Group Info Services AG © 2023
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
