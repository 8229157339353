import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Check } from "../../style/assets/icons/check.svg";
import { ReactComponent as Decline } from "../../style/assets/icons/decline.svg";
import { ReactComponent as Failure } from "../../style/assets/icons/failure.svg";

const Result = () => {
  const nav = useNavigate();
  const currentURL = new URL(window?.location?.href);
  const result = currentURL.searchParams.get("result");
  const [type, setType] = useState(<Check />);
  const [text, setText] = useState<string>();
  const [subText, setSubText] = useState<string>();
  const [moreSubText, setMoreSubText] = useState<string>();

  useEffect(() => {
    if (result === "success") {
      setType(<Check />);
      setText("Sie haben erfolgreich Ihre Zustimmung erteilt");
      setSubText(
        "Wir stellen nun Ihre Daten der von Ihnen ausgewählten Versicherung bereit."
      );
    } else if (result === "decline") {
      setType(<Decline />);
      setText("Sie haben Ihre Zustimmung nicht erteilt");
      setSubText(
        "Daher können wir Ihrer Versicherung keinen Zugriff auf Ihre Daten gewähren."
      );
      setMoreSubText(
        "Falls Sie der Datenübertragung zu einem späteren Zeitpunkt dennoch zustimmen möchten, öffnen Sie bitte erneut den Link, den Ihnen Ihre Versicherung per E-Mail zugesandt hat und erteilen Sie Ihre Zustimmung."
      );
    } else if (result === "failure") {
      setType(<Failure />);
      setText("Ein technischer Fehler ist aufgetreten.");
      setSubText(
        "Bitte öffnen Sie den von Ihrer Versicherung per E-Mail zugesandten Link erneut, um einen weiteren Versuch zu unternehmen."
      );
    } else {
      nav("/error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <div
      className="mb-24 mt-16 max-w-maxContent px-8 md:mx-auto"
      data-testid="result-page-container"
    >
      <div
        className="md:flex lg:w-2/3"
        data-testid="result-content-page-text-container"
      >
        <div
          className="mb-20 flex justify-center"
          data-testid={`result-icon-${result}`}
        >
          {type}
        </div>
        <h1
          className="mb-8 font-akzidenz text-dispLgMob font-bold uppercase md:ml-8 md:text-dispLgDesk"
          data-testid="result-content-page-text-healine"
        >
          {text}
        </h1>
      </div>
      <div className="font-montreal text-textSm font-medium lg:w-2/3">
        {subText}
      </div>
      <div className="font-montreal text-textSm font-medium lg:w-2/3">
        {moreSubText && moreSubText}
      </div>
    </div>
  );
};

export default Result;
