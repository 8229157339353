import { ReactComponent as Logo } from "../../style/assets/images/logo.svg";

const Header = () => {
  return (
    <header className="fixed top-0 left-0 z-20 h-20 w-full bg-primary-dark p-4 text-primary-white">
      <div
        className="mx-auto flex h-full max-w-maxContent items-center"
        data-testid="header-container"
      >
        <Logo data-testid="ui-header-logo" />
      </div>
    </header>
  );
};

export default Header;
