import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Layout from "./components/Layout/Layout";
import { AuthProvider } from "./context/authProvider";
import ApplyConsent from "./page/ApplyConsent";
import Consent from "./page/Consent";
import Error404 from "./page/Error404/Error404";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Result from "./page/Result";
import Welcome from "./page/Welcome";
import i18n from "./services/i18n";

function App() {
  const language = localStorage.getItem("language");
  const [lang, setLang] = useState(
    language === null ? i18n.getDefaultLocale() : language
  );
  const [messages, setMessages] = useState();

  const handleError = () => {
    //switch off translation logs
  };

  useEffect(() => {
    setLang(language === null ? i18n.getDefaultLocale() : language);
    i18n.getMessages(lang).then((data) => {
      setMessages(data);
    });
  }, [lang, language]);
  return (
    <BrowserRouter>
      <AuthProvider>
        <IntlProvider
          messages={messages}
          key={lang}
          locale={lang}
          defaultLocale={i18n.getDefaultLocale()}
          onError={handleError}
        >
          <Header />
          <main
            className="bg-grey-100 text-grey-primary mt-20 flex grow flex-col"
            data-testid="consent-ui-main-id"
          >
            <Routes>
              <Route element={<Layout />}>
                <Route path="oauth/callback" element={<Welcome />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="*" element={<Error404 />} />
                <Route path="consent-result" element={<Result />} />
              </Route>
              <Route path="give-consent" element={<Consent />} />
              <Route path="apply-consent" element={<ApplyConsent />} />
            </Routes>
          </main>
        </IntlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
