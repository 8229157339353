import { FormattedMessage } from 'react-intl';

const Privacy = () => {
  return (
    <article className="max-w-maxContent px-4 py-24 md:mx-auto">
      <div className="text-textMdMob lg:w-2/3">
        <h1
          className="pb-10 font-akzidenz uppercase md:text-textMdDesk"
          data-testid="privacy-notice-page-topic"
        >
          <FormattedMessage
            id="screen_consentui_privacypolicy_pagetitle"
            defaultMessage=" Grundgerüst"
          />
        </h1>

        <p>
          <FormattedMessage
            id="screen_consentui_privacypolicy_brandinfo"
            defaultMessage="Volkswagen Group Info"
          />
        </p>
        <p className="pb-4">
          <FormattedMessage
            id="screen_consentui_privacypolicy_date"
            defaultMessage="datum und stand"
          />
        </p>
        <p className="pb-4">
          <FormattedMessage
            id="screen_consentui_privacypolicy_intro_1"
            defaultMessage="Verarbeitung Ihrer personenbezogenen Daten"
          />
        </p>
        <p className="pb-4">
          <FormattedMessage
            id="screen_consentui_privacypolicy_intro_2"
            defaultMessage="Verarbeitung Ihrer personenbezogenen Daten"
          />
        </p>

        <ul className="list-inside list-[upper-alpha]">
          <FormattedMessage
            id="screen_consentui_privacypolicy_headline_a"
            defaultMessage="Verantwortlicher für die Datenverarbeitung"
          />
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_a1"
              defaultMessage=" Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten
            ist die Volkswagen Group Info Services AG (nachfolgend auch „wir“
            oder „uns“)."
            />
          </p>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_a2"
              defaultMessage=" Sie können die Volkswagen Group Info Services AG unter folgender
            Adresse erreichen:"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_address_description_a"
              defaultMessage="Volkswagen Group Info Services AG"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_vw_address_street"
              defaultMessage=" Berliner Ring 2"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_vw_address_city"
              defaultMessage="38440 WOLFSBURG"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_vw_address_country"
              defaultMessage="DEUTSCHLAND"
            />
          </p>
          <a
            className="text-accent-dark"
            href="mailto:volkswagen.group.data.business@cariad.technology"
          >
            <FormattedMessage
              id="screen_consentui_privacypolicy_vw_email"
              defaultMessage="email"
            />
          </a>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_register_number"
              defaultMessage="Handelsregisternummer"
            />
          </p>

          <li className="mb-5 font-bold">
            <FormattedMessage
              id="screen_consentui_privacypolicy_headline_b"
              defaultMessage="Datenschutzbeauftragter headline"
            />
          </li>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_b1"
              defaultMessage="Unser Datenschutzbeauftragter steht Ihnen als Ansprechpartner für
              sämtliche datenschutzbezogenen Anliegen zur Verfügung."
            />
          </p>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_b2"
              defaultMessage="Bitte richten Sie Ihre Anfragen an"
            />
          </p>

          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_address_description_b"
              defaultMessage="Datenschutzbeauftragter der Volkswagen Group Info Services AG"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_cariad_address_street"
              defaultMessage=" Berliner Ring 2"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_cariad_address_city"
              defaultMessage="38440 WOLFSBURG"
            />
          </p>
          <p>
            <FormattedMessage
              id="screen_consentui_privacypolicy_cariad_address_country"
              defaultMessage="DEUTSCHLAND"
            />
          </p>
          <a
            className="text-accent-dark "
            href="mailto:privacy@cariad.technology"
          >
            <FormattedMessage
              id="screen_consentui_privacypolicy_cariad_email"
              defaultMessage="privacy@cariad.technology"
            />
          </a>
          <p className="pb-4 pt-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_b3"
              defaultMessage="Für die Ausübung Ihrer Rechte bitten wir Sie, sich direkt an"
              values={{
                a: (chunks) => (
                  <a
                    href="mailto:privacy@cariad.technology"
                    className="text-accent-dark"
                  >
                    privacy@cariad.technology
                  </a>
                ),
              }}
            />
          </p>

          <li className="mb-5 font-bold">
            <FormattedMessage
              id="screen_consentui_privacypolicy_headline_c"
              defaultMessage="Datenverarbeitung"
            />
          </li>

          <ul className="ml-4 list-inside list-decimal pb-4">
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c1"
                defaultMessage="DatenverarbUse Case Beschreibung aus End User Sichteitung"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c2"
                defaultMessage="Personenbezogene Daten"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c3"
                defaultMessage="Rechtsgrundlage"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c4"
                defaultMessage="Datenempfänger"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c5"
                defaultMessage="Quellen der Daten"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c6"
                defaultMessage="Speicherdauer"
              />
            </li>
            <li>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_c7"
                defaultMessage="Speiche Ist die Bereitstellung der Daten gesetzlich oder vertraglich
                erforderlichdauer"
              />
            </li>
          </ul>
          <li className="mb-5 font-bold">
            <FormattedMessage
              id="screen_consentui_privacypolicy_headline_d"
              defaultMessage="Ihre Rechte"
            />
          </li>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_d1"
              defaultMessage="Ihre nachfolgenden Rechte können Sie gegenüber der Volkswagen Group
              Info Services AG jederzeit unentgeltlich geltend machen."
            />
          </p>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_d2"
              defaultMessage="Bitte beachten Sie, dass wir den jeweiligen Fahrzeugnutzer nicht
              identifizieren, wenn kein Nutzerkonzept besteht"
            />
          </p>
          <p className="pb-4">
            <FormattedMessage
              id="screen_consentui_privacypolicy_text_d3"
              defaultMessage="Weitere Informationen zur Wahrnehmung Ihrer Rechte finden Sie auf
              der Webseite LINK"
            />
          </p>

          <ul className="list-inside list-[upper-roman] ">
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d31"
                defaultMessage="Auskunftsrecht"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d31"
                defaultMessage="Sie haben das Recht, von uns eine Bestätigung darüber zu
              verlangen"
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d32"
                defaultMessage="Recht auf Berichtigung"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d32"
                defaultMessage="sie haben das Recht, von uns die Berichtigung"
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d33"
                defaultMessage="Recht auf Löschung"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d33"
                defaultMessage="Sie haben das Recht, bei Vorliegen der in Art. 17 DSGVO genannten
                Voraussetzungen die Löschung Ihrer Daten zu verlangen"
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d34"
                defaultMessage="Recht auf Einschränkung der Verarbeitung"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d34"
                defaultMessage="Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                Daten zu verlangen"
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d35"
                defaultMessage="WIDERSPRUCHSRECHT"
              />{' '}
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d35"
                defaultMessage=" Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer
                Daten in den folgenden Fällen zu:"
              />
            </p>
            <ul className="ml-4 list-inside list-disc">
              <li>
                <FormattedMessage
                  id="screen_consentui_privacypolicy_text_points2_d35"
                  defaultMessage="Wenn die Verarbeitung zu Zwecken der Direktwerbung
                (einschließlich Profiling zu Zwecken der Direktwerbung) erfolgt."
                />
              </li>
              <li>
                <FormattedMessage
                  id="screen_consentui_privacypolicy_text_points2_d35"
                  defaultMessage=" Wenn die Verarbeitung (einschließlich Profiling) auf eine der
                folgenden Rechtsgrundlagen gestützt wird:"
                />
              </li>
              <ul className="ml-6 list-inside list-[circle]">
                <li>
                  <FormattedMessage
                    id="screen_consentui_privacypolicy_text_points21_d35"
                    defaultMessage=" Die Verarbeitung ist für die Wahrnehmung einer Aufgabe
                erforderlich"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="screen_consentui_privacypolicy_text_points22_d35"
                    defaultMessage=" Die Verarbeitung ist zur Wahrung der berechtigten Interessen
                von uns oder einem Dritten erforderlich"
                  />
                </li>
              </ul>
            </ul>
            <li className="pt-4 font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d36"
                defaultMessage="Recht auf Datenübertragbarkeit"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d36"
                defaultMessage=" Sofern die Datenverarbeitung auf der Grundlage einer Einwilligung
                oder einer Vertragserfüllung beruht und diese zudem unter Einsatz
                einer automatisierten Verarbeitung erfolgt"
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d37"
                defaultMessage="Widerrufsrecht"
              />
            </li>
            <p className="pb-4">
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d37"
                defaultMessage="  Sofern die Datenverarbeitung auf einer Einwilligung beruht, haben
                Sie das Recht, Ihre Einwilligung jederzeit kostenlos mit Wirkung
                für die Zukunft per Mail an info-datenschutz@volkswagen.de;
                datenschutz@volkswagen.de oder über die Kontaktdaten im Impressum
                zu widerrufen."
              />
            </p>
            <li className="font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d38"
                defaultMessage="Beschwerderecht"
              />
            </li>
            <p>
              <FormattedMessage
                id="screen_consentui_privacypolicy_text_d38"
                defaultMessage=" Sie haben außerdem das Recht, sich bei einer Aufsichtsbehörde oder
                einer anderen zuständigen Datenschutzbehörde über unsere
                Verarbeitung Ihrer Daten zu beschweren."
              />
            </p>
            <a
              className="text-accent-dark"
              href="https://edpb.europa.eu/about-edpb/about-edpb/members_de"
            >
              <FormattedMessage
                id="screen_consentui_privacypolicy_eu_privacy_link"
                defaultMessage="EU privacy link"
              />
            </a>
            .
            <li className="mt-5 font-bold">
              <FormattedMessage
                id="screen_consentui_privacypolicy_title_d39"
                defaultMessage="Automatisierte Einzelentscheidungen"
              />
            </li>
          </ul>
        </ul>
      </div>
    </article>
  );
};

export default Privacy;
