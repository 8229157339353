import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/useAuth";
import {
  CONSENT_GET_DOCUMENT_PATH,
  CONSENT_WHITLISTING_PATH,
} from "../../util/endpoints";
import { getWithCredentials } from "../../util/fetch/fetchRequests";
import { MAGIC_LINK_PARAM } from "../../util/types";
import {
  createAuthObj,
  getJwtDecode,
  getValueStateParams,
  getValueUriParams,
  validateToken,
} from "./welcome";

export interface IConsent {
  consentRequired: boolean;
  documentId: string;
  consent: {
    decision: boolean;
    id: string;
    versionDetailsId: string;
    userId: string;
    version: number;
    collector: string;
    updatedAt: string;
  };
  versionDetails: {
    versionId: string;
    locale: string;
    disclaimer: string;
    text: string;
    title: string;
    id: string;
  };
}

const Welcome = () => {
  const nav = useNavigate();
  const currentURL = new URL(window?.location?.href).hash;
  const params = { id_token: "", access_token: "", state: "", exp: 0 };

  const auth = useAuth();

  function extractAndSaveLoginResponseParams() {
    params.id_token = getValueUriParams(currentURL, "id_token");
    params.access_token = getValueUriParams(currentURL, "access_token");
    params.state = getValueUriParams(currentURL, "state");
  }

  function goToErroPage() {
    nav("/error");
  }

  const getConsentDocument = async () => {
    return getWithCredentials(
      `${CONSENT_GET_DOCUMENT_PATH}?subscriptionId=${getValueStateParams(
        params.state,
        MAGIC_LINK_PARAM.subscriptionId
      )}&brandName=${getValueStateParams(
        params.state,
        MAGIC_LINK_PARAM.brand
      )}`,
      params.access_token
    );
  };

  async function checkRedirectIsWhiteListed() {
    await getWithCredentials(
      CONSENT_WHITLISTING_PATH(
        getValueStateParams(params.state, MAGIC_LINK_PARAM.subscriptionId),
        getValueStateParams(params.state, MAGIC_LINK_PARAM.redirect)
      )
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!!currentURL) {
        try {
          extractAndSaveLoginResponseParams();
          await checkRedirectIsWhiteListed();

          const jwt = getJwtDecode(params.id_token);

          if (validateToken(jwt)) {
            const consent: IConsent = await getConsentDocument();
            auth.addAuth(
              createAuthObj(params.access_token, params.state, consent, jwt)
            );

            nav("/apply-consent", { state: consent });
            return;
          } else {
            goToErroPage();
          }
        } catch (e) {
          goToErroPage();
        }
      } else {
        goToErroPage();
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div data-testid="welcome-page-container"></div>;
};

export default Welcome;
