import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Redirect from '../../components/Redirect/Redirect'
import {
  CONSENT_WHITLISTING_PATH,
  IDENTITY_KIT_LOGIN_URL,
  IDENTITY_KIT_LOGIN_URL_PATH,
  IDENTITY_KIT_NONCE,
  IDENTITY_KIT_REDIRECT_URI_PATH,
  URI_SEPARATION_SIGN,
} from '../../util/endpoints'
import { getWithCredentials } from '../../util/fetch/fetchRequests'
import { MAGIC_LINK_PARAM } from '../../util/types'

const Consent = () => {
  const nav = useNavigate()
  const [externalRedirectUri, setExternalRedirectUri] = useState<
    string | undefined
  >('https://identity.vwgroup.io/')

  const currentURL = new URL(window?.location?.href)
  const subscriptionId = currentURL.searchParams.get(
    MAGIC_LINK_PARAM.subscriptionId,
  )

  const state = currentURL.searchParams.get(MAGIC_LINK_PARAM.state)
  const brand = currentURL.searchParams.get(MAGIC_LINK_PARAM.brand)
  const language = currentURL.searchParams.get(MAGIC_LINK_PARAM.language)
  const redirect = currentURL.searchParams.get(MAGIC_LINK_PARAM.redirect)

  function isUrlValid() {
    return !!subscriptionId && !!brand && !!redirect
  }

  function getUrlParam(paramName: MAGIC_LINK_PARAM, param: string | null) {
    return paramName + ':' + param + URI_SEPARATION_SIGN
  }

  function showErrorPage() {
    nav('/error')
  }

  async function redirectToLogin() {
    if (isUrlValid()) {
      try {
        await getWithCredentials(
          CONSENT_WHITLISTING_PATH(subscriptionId!, encodeURI(redirect!)),
        )
        setExternalRedirectUri(
          IDENTITY_KIT_LOGIN_URL +
            IDENTITY_KIT_LOGIN_URL_PATH +
            '?' +
            MAGIC_LINK_PARAM.clientId +
            '=' +
            process.env.REACT_APP_IDENTITY_KIT_CLIENT_ID +
            '&scope=openid' +
            '&response_type=id_token%20token' +
            '&nonce=' +
            IDENTITY_KIT_NONCE +
            '&redirect_uri=' +
            window.location.origin +
            IDENTITY_KIT_REDIRECT_URI_PATH +
            '&state=' +
            getUrlParam(MAGIC_LINK_PARAM.subscriptionId, subscriptionId) +
            (state ? getUrlParam(MAGIC_LINK_PARAM.state, state) : '') +
            getUrlParam(MAGIC_LINK_PARAM.brand, brand) +
            getUrlParam(MAGIC_LINK_PARAM.language, language ?? 'de_DE') +
            getUrlParam(MAGIC_LINK_PARAM.redirect, encodeURI(redirect!)),
        )
      } catch (e) {
        showErrorPage()
      }
    } else {
      showErrorPage()
    }
  }

  function setLanguage() {
    if (!!language) {
      localStorage.setItem('language', language)
    }
  }

  useEffect(() => {
    setLanguage()
    redirectToLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, state, brand, language, redirect])

  return (
    <>
      {externalRedirectUri && (
        <Redirect
          page={externalRedirectUri}
          cancel={() => {
            setExternalRedirectUri(undefined)
            showErrorPage()
          }}
        />
      )}
      <div data-testid="content-page-container" />
    </>
  )
}

export default Consent
