export const IDENTITY_KIT_LOGIN_URL = process.env.REACT_APP_IDENTITY_KIT_HOST;
export const IDENTITY_KIT_LOGIN_URL_PATH = "/oidc/v1/authorize";
export const IDENTITY_KIT_NONCE = process.env.REACT_APP_IDENTITY_KIT_NONCE;
export const IDENTITY_KIT_REDIRECT_URI_PATH = "/oauth/callback";
export const URI_SEPARATION_SIGN = "%20";

export const CONSENT_HOST = process.env.REACT_APP_CONSENT_HOST;
export const CONSENT_WHITLISTING_PATH = (subscription: string, uri: string) =>
  CONSENT_HOST +
  `/valid-redirect-uris?subscriptionId=${subscription}&uri=${uri}`;
export const CONSENT_GET_DOCUMENT_PATH = CONSENT_HOST + "/consent-documents";
export const CONSENT_GRANT_PATH = CONSENT_HOST + "/consents";

export const REACT_APP_DATAHUB_PATH = process.env.REACT_APP_DATAHUB_HOST;
