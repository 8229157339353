import { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../style/assets/icons/close.svg";
import { ReactComponent as Success } from "../../style/assets/icons/success.svg";
import { ReactComponent as Error } from "../../style/assets/icons/error.svg";

const Banner = ({
  text,
  type,
  visible,
  testId,
}: {
  text: string;
  type: "success" | "error";
  visible: boolean;
  testId: string;
}) => {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    setBanner(visible);
  }, [text, type, visible]);

  function getBannerTypeCss() {
    if (type === "success") {
      return "text-notify-success stroke-notify-success bg-notifyb-success";
    } else {
      return "text-notify-error stroke-notify-error bg-notifyb-error";
    }
  }

  function getImageByType() {
    if (type === "success") {
      return <Success className="mr-2 w-6 " />;
    } else {
      return <Error className="mr-2 w-6 " />;
    }
  }

  function handleClose() {
    setBanner(false);
  }

  return (
    <>
      {banner && (
        <div
          className={`fixed top-20 z-10 flex w-full items-center justify-between rounded-[2px] px-4 ${getBannerTypeCss()}`}
          data-testid={testId}
        >
          <div>{getImageByType()}</div>
          <div className="grow text-textSm font-medium">{text}</div>
          <button
            className="w-10 p-2.5"
            data-testid="gf-banner-close"
            onClick={handleClose}
          >
            <Close className="w-5" />
          </button>
        </div>
      )}
    </>
  );
};

export default Banner;
